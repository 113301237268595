.App {
  min-height: 100vh;
  background-color:#07071d;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.topdiv{
  text-align:center;
  height: 10vh;
}

.topspan{
color: white;
font-size: 5vw;
}

.mslist{
  width: 90vw;
  height: 75vh;
  margin: auto;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  background-size: cover !important;
}

li{
  list-style: none;
  display: block;
  border-top: #373749 thin solid;
  height: 5.76vh;
  line-height: 5.76vh;
}
.mslist ul li:last-child{
  border-bottom:  #373749  thin solid;
}
span{
  font-size: 3vw;
}
.mslist li span:first-child{
  display: block;
  float: left;
  width: 25vw;
  text-align: right;
  margin-left: 5vw;
  color: white;

}
.mslist li span:last-child{
  display: block;
  float: left;
  width: 55vw;
  /* 强制文字一行显示 */
  white-space: nowrap;
  /* 溢出隐藏 */
  overflow: hidden;
  /* 后面显示 ... */
  text-overflow: ellipsis;
}
.foot{
  width: 90vw;
  margin: auto;
  height: 15vh;
  line-height: 15vh;
}
.foot input{
  width: 100%;
  height: 7.5vh;
  border: none;
  outline: none;
  text-align: center;
  background-color: #3e6bec;
  padding: 0 ;
  border-radius: 5px;
  color: #ffffff;
}
.rightbox{
  position: fixed;
  top: 60vh;
  right: 0;
  width: 20%;
  height: 5vh;
}
.rightbox img{
  width: 100%;
  height: 100%;
}

.solvetag{
  position: absolute;
  background-size:contain !important ;
  top: 20vw;
  right: 5vw;
  width: 30vw;
  height: 30vw;
}


/* toast */
.toast{
  position: fixed;
  width: 80vw;
  height: 90vw;
  top: 20vh;
  left: 10vw;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
  font-size: 4vw;
}
.toasttop{
  background: url(./img/bg3.png) no-repeat;
  background-size: contain;
  position: absolute;
  width: 20vw;
  height: 20vw;
  top:-10vw;
  left: 30vw;
}
.toastcanle{
  width: 18px;
  height: 18px;
  background: url(./img/bg4.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 5px;
  right: 5px;
}
.toastform{
  width: 80%;
  margin:10vw auto 0 auto;
  height: 10vw;
  line-height: 10vw;
}
.toastform input{
  font-size: 3vw;
  padding-left: 5%;
  width: 100%;
  height: 10vw;
  border: gray thin solid;
  border-radius: 2px;
  background-color: #fbfbfb;
}
.toastform form span{
  cursor: pointer;
}
.toastform form  span:nth-of-type(2){
  color: #3e6bec;
}
.toastform form span:nth-of-type(1){
  background-image: url(./img/bg5.png);
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.toastform form span:nth-of-type(3){
  background-image: url(./img/bg6.png);
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.toastfoot{
  font-size: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.toastfoot input:first-child{
  width: 50%;
  height: 10vw;
  background-color: #e7edff;
  border: none;
  outline: none;
  color: #6287f1;
  border-radius: 0px !important;
}
.toastfoot input:last-child{

  width: 50%;
  height: 10vw;
  background-color: #3e6bec;
  border: none;
  outline: none;
  color: white;
  border-radius: 0px !important;
}

/* solve */
.solve{
  color: white;
  margin-top: 3vh;
  background:url(./img/background.png) no-repeat;
  background-size: cover !important;
  background-position: -0px -30px !important;
  width: 90vw;
  margin: auto;
  overflow: hidden; 
  border-radius: 5px;
  margin-top: 3vh;
}

.solve li{
 height: 5vh;
 line-height: 5vh;
 border-top: none;
 border-bottom: #373749  thin solid;
}
.solveTop{
  height: 10vh !important; 
  display: flex;
  align-items: center;
}
.solveTop div{
  height: 6vh;
  width: 6vh;
  background-size: 100% 100% !important;
  border-radius: 3vh;
  margin-right: 5vw;
  margin-left: 15vw;
}
.solveTop span:last-child{
  margin-left: 15vw;
}
.solve ul li span:first-child{
  display: inline-block;
  width: 25vw;
  text-align: right;
  margin-left: 5vw;
}
.solve ul li span:last-child{
  color: #696971;
}
.solve ul li:last-child div{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #26b487;
  height: 7.5vh;
}
.solve ul li:last-child div span{
  color: #26b487;
}
.lastli{
  height: 7.5vh !important;
  border-bottom: none !important;
}
.footspan{
  display: inline-block;
  width: 3vw !important;
  height: 3vw;
  background: url(./img/bg8.png) no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-left: 0 !important;
}

.promptBox{
  position: fixed;
  top:40vh;
  left: 30vw;
  width: 40vw;
  height: 15vh;
  text-align: center;
  line-height: 15vh;
  font-size: 4vw;
  z-index: 1;
  background-color: aliceblue;
  border-radius: 3vw;
  opacity: 0.7;
}