/* 全局配置 初始化样式样式 */
.repair{
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 100vh;
    background-color: #07071d;
    padding-bottom: 2vh;
}
.repaircenter{
    width: 90vw;
    margin: auto;
}
.repaircenter .repairTop{
    text-align: center;
    color: white;
    height: 16vh;
    font-size: 5vw;
}
.pointimg{
    position: absolute;
    /*width: 15vw;*/
    height: 5vw;
    right: 3vw;
    top:3vw;
}
.pointimg span:first-child{
    float: left;
    display:block;
    width: 5vw;
    height: 100%;
    background-image: url(./img/point.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.pointimg span:last-child{
    float: left;
    display:block;
    max-widthwidth: 20vw;
    height: 100%;
    padding: 0 3px;
    border-radius: 2.5vw;
    border: #3e6bec thin solid;
    color: #3e6bec;
    text-align: center;
    /* 强制文字一行显示 */
    white-space: nowrap;
    /* 溢出隐藏 */
    overflow: hidden;
    /* 后面显示 ... */
    text-overflow: ellipsis;
}
.repairRight{
    position: fixed;
    top: 60vh;
    right: 0;
    width: 20%;
    height: 5vh;
    text-align: center;
    line-height: 5vh;
    background-size:contain !important;
    z-index: 5;
  }
.repairRight img{
    width: 100%;
    height: 100%;
}

.repairmess{
    position: relative;
    padding-top: 15vw;
    padding-left: 10vw;
    padding-bottom: 6vw;
    background-color: #232337;
    border-radius: 3vw;
    margin-bottom: 6vw;
}
.repairmess .messtop{
    position: absolute;
    width: 30vw;
    height:30vw;
    top:-15vw;
    left:30vw;
    background:url(./img/bg3.png) no-repeat;
    background-size: contain;
    padding: 2vw;
}
.repairmess .messtop div{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: 100% 100% !important;
}
.repairmess .repairlist{
    color: white;
}
.repairmess .repairlist span{
    /* 强制文字一行显示 */
    white-space: nowrap;
    /* 溢出隐藏 */
    overflow: hidden;
    /* 后面显示 ... */
    text-overflow: ellipsis;
}
.repairlist li span:first-child{
    display: block;
    float: left;
    width: 57%;
}
.repairlist li span:last-child{
    display: block;
    float: left;
    width: 43%;
}
.repairmess .repairlist li{
    height: 7vw;
    line-height: 7vw;
    border: none;
}
.repairmess .repairlist li:nth-child(2n-1) span{
    font-size: 4vw !important;
    color: #d9dad9;
}
.repairlist li:nth-child(2n){
    font-size: 3vw;
    margin-bottom: 2vw;
    color: #979797;
}
.errorimg{
    height: 20vw !important;
    margin-bottom: 2vw;
    margin-top: 2vw;
}
.errorimg div{
    float:left;
    margin-right: 2vw;
    width: 20vw;
    height: 20vw;
    background: url(./img/rect.png)  no-repeat;
    background-size: contain;
    padding: 1.5vw;
}
.errorimg img{
    width: 100%;
    height: 100%;
    border-radius: 1vw;
}


.ratespan{
    color: #feffff;
    font-size: 5vw;
}
.repairRate{
    padding-top: 5vw;
    padding-left: 10vw;
    padding-bottom: 8vw;
    background-color: #232337;
    border-radius: 3vw;
    margin-top: 4vw;
    margin-bottom: 6vw;
}

/* step */
.repairstep{
    padding-top: 5vw;
    padding-left: 10vw;
    border-left: #39394b thin solid;
}
.stepright{
    position: relative;
    color: white;
}
.repairstep .stepright li{
    border: none;
}
.repairstep .stepright li:first-child{
    height: 4vh;
    
}
.repairstep .stepright li:nth-child(n+2){
    height: 3vh;
    line-height: 3vh;
}
.repairstep .stepright li span:first-of-type{
    color: #dadbda;
} 
.repairstep .stepright li span:last-of-type{
    color: #979797;
}
.stepleft{
    width: 4vh;
    height: 4vh;
    top:1vh;
    left: calc(-2vh + -10vw);
    position: absolute;
    background-repeat:no-repeat !important;
    background-size: contain !important;
    /* background-image: url(./img/tag.PNG); */
}