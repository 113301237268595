.maintenance{
    background-color: #07071d;
}
.maintenCenter{
    width: 90vw;
    margin: auto;
    min-height: 100vh; 
    color: white;   
}
.miantop{
    text-align: center;
    font-size: 5vw;
    margin-bottom: 5.5vh;

}
.mainmid{
    padding: 0 5vw;
    overflow: hidden;
    margin-bottom: 2vh;
}
.mainmid .firstfont{
    display: inline-block;
    height: 100%;
    border-bottom: #9953d3 3px solid;
    font-size: 7vw;
}
.mainmid div:first-child{
    height: 7vh;
    line-height: 7vh;
    float: left;
    font-size: 7vw;
}
.mainmid div:last-child{
    margin-top: 1.5vw;
    height: 7vh;
    line-height: 7vh;
    font-size: 4vw;
    float: right;
}
.mainfoot{
    padding-top: 1vh;
    padding-bottom: 3vh;
    background-color: #232337;
    border-radius: 3vw;
}
.mainfoot .mainfootlist li{
    list-style: none;
    border: none;
    height: 3vh;
    line-height: 3vh;
}
.mainfoot .mainfootlist li span:first-child{
    display: inline-block;
    width: 18vw;
    text-align: right;
    color:#dadbda;
}
.mainfoot .mainfootlist li span:last-child{
    color: #979797;
}
/*分割线 带半圆*/
.linediv{
    height: 4vh;
    width: 100%;
}
.linediv div{
    float: left;
}
.linediv div:nth-child(1){

    width: 4vh;
    height: 100%;
    background-color:#07071d ;
    margin-left: -2vh;
    border-radius: 2vh;
}
.linediv div:nth-child(2){
    width: calc(90vw - 4vh - 1px);
    height: 100%;
    background: url("./img/Line.png")  repeat-x;
    background-position: center;
}
.linediv div:nth-child(3){
    width: 4vh;
    height: 100%;
    background-color:#07071d ;
    border-radius: 2vh;
    margin-right: -2vh;
}

/*纯分割线*/
.divider{
    width:calc(90vw - 4.5vh);
    margin: auto;
    height: 4vh;
    background: url("./img/Line.png")  repeat-x;
    background-position: center;
}

.paln div{
    text-align: center;
    font-size: 3vw;
    color: #979797;
    margin-bottom: 1vw;
}
.paln div:first-child{
    color: #dadbda;
}

/*finissh*/
.finishtime div:nth-child(1){
    margin: 2px 0;
    height: 8vw;
    width: 100%;
    background-image: url("./img/time.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
}
.finishtime div:nth-child(2){
    color: #dadbda;
}

.serviceRe{
    text-align: center;
    font-size: 3vw;
    color: #dadbda;
}
.serviceRe div{

    margin: 2vw auto;
    width: 40vw;
    height: 12vh;
    padding: 2vw;
    border-radius: 2vw;
    background-color: #474757;;
}
.serviceRe div img{
    width: 100%;
    height: 100%;
    border-radius: 2vw;
}