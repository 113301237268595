/* 全局配置 初始化样式样式 */
.appointment{
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 100vh;
    background-color: #000000;
    padding-bottom: 2vh;
}
.appoint{
    width: 90vw;
    margin: auto;
}
.appoint .appointTop{
    text-align: center;
    color: white;
    height: 8vh;
    font-size: 5vw;
}
.appointRight{
    position: fixed;
    top: 60vh;
    right: 0;
    width: 20%;
    height: 5vh;
    text-align: center;
    line-height: 5vh;
    background-size:contain !important;
    z-index: 1;
  }
.appointRight img{
    width: 100%;
    height: 100%;
}

  /* nostart */
  .appointBody{
    background-color: #232337;
    color: #c5c6c7;
    padding:1vh 0;
    border-radius: 2vw;
    margin-bottom: 2vh;
}
.appointBody .messlist{
    margin: auto;
    width: 87%;

}
.appointBody .messlist li{
    border: none;
}
.appointBody .messlist li span:first-child{
    display: block;
    float: left;
}
.appointBody .messlist li span:last-child{
    display: block;
    float: right;
}
.appointBody .messlist li{
    height: 4vh;
    line-height: 4vh;
}
.appointBody .messlist li:nth-child(2n){
    margin-bottom: 2vh;
    color: #87878a;
}
.appointBody .messlist li:nth-child(6){
    margin-bottom: 0;
}
.appointBody .messlist li:nth-child(1) span{
    width: 33%;
}
.appointBody .messlist li:nth-child(1) span:last-child{
    text-align: end;
}
.appointBody .messlist li:nth-child(2){
    background-size: contain !important;
    background-position: center !important;
}

.spantop{
    display: block;
    float: left;
    margin: auto;
    text-align: center;
}


.listFoot{
    width: 87%;
    margin: auto;
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    border-top: #373749 thin solid;
    font-size: 3vw;
}

.remarkToast{
    position: absolute;
    width: 75vw;
    height: 70vw;
    background: #fff;
    top:35vh;
    left: 12.5vw;
    border-radius: 2vw;
    z-index: 1;
}
.remarkToast div:nth-child(3){
    margin-top: 15%;
    margin-left: 10%;
    margin-bottom: 4%;
    font-size: 5vw;
}
.remarkToast textarea{
    display: block;
    margin-left: 10%;
    width: 80%;
    height: 40%;
    resize: none;
    background-color: #fbfbfb;
    outline: none;
    border: #dedede thin solid;

    font-size: 4vw;
    padding: 2vw;
}
.remarkToast input{
    position: absolute;
    bottom: 0;
    outline: none;
    border: none;
    width: 50%;
    height: 15%;
}
.remarkToast input:nth-of-type(1){
    left: 0;
    background-color: #e7edff;
    color: #6287f1;
}
.remarkToast input:nth-of-type(2){
    right: 0;
    background-color: #3e6bec;
    color: white;
}
.remaskTop{
    position: absolute;
    background: url("./img/reject.png") no-repeat;
    background-size:cover;
    width:calc(75vw * 0.25 );
    height:calc(75vw * 0.25 );
    margin-left: 50%;
    left:calc(-75vw * 0.125) ;
    top:calc(-75vw * 0.25 * 2 / 3);
    z-index: 2;
}
.remaskCancle{
    position: absolute;
    background: url("./img/close.png") no-repeat;
    background-size: contain;
    width: 4.5vw;
    height: 4.5vw;
    top:2vw;
    right: 2vw;
}

/*查看备注时 顶部的样式*/
.remasktop{
    text-align: center;
    font-size: 3vw !important;
}
.remaskLi{
    height: 16vh !important;
    line-height: 4vh !important;
    font-size: 3vw !important;
}
.foottoast{
    text-align: center;
    color: #979797;
}